import React from "react"
import News from "../../blocks/news"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "osipov_vs_liepaja" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Вчера Catenaccio Sports Agency добилось в ФИФА положительного исхода дела против ФК «Лиепая». Футболист и клиент агентства <Link to="/players/osipov">Артём Осипов</Link> решением ФИФА получит серьёзную финансовую компенсацию от ФК «Лиепая» за неправомерное расторжение трудового договора.</p>
          <p>Напомним, что конфликт произошёл в период правления в клубе Мариса Верпаковскиса, а одним из катализаторов дела стало некорректное интервью на тот момент главного тренера Тамаза Пертии. Надеемся, что это послужит хорошим уроком для остальных прибалтийских футбольных клубов, и в будущем побудит регулировать все вопросы профессионально и дипломатично, как это и должно быть в серьёзных футбольных клубах.</p>
          <p>Также хотелось бы отметить, что к действующему руководству ФК «Лиепая» у футболиста Артёма Осипова нет никак претензий, т.к они, к сожалению, косвенно пострадали от серьёзных погрешностей предыдущего руководства и таким образом вынуждены исполнять решение ФИФА в полном объёме.</p>
        </div>
      </div>
    )
  }
}